import React from 'react'
import Layout from '../Components/Layout'
import WhatsappButton from '../Components/WhatsappButton'
import Products from './Products'
// import products from '../Data/JobData'



const Home = () => {
  const filters = [
    {
      id: "color",
      name: "Category",
      options: [
        { value: "indoor", label: " Indoor" },
        { value: "outdoor", label: " Outdoor" },
       
      ],
    },
    {
      id: "category",
      name: "Location",
      options: [
        { value: "onsite", label: "Onsite" },
        { value: "remote", label: "Remote" },
        { value: "hybrid", label: "Hybrid" },
        
      ],
    },
    {
      id: "sizes",
      name: "Experience",
      options: [
        { value: "fresher", label: "Fresher" },
        { value: "juniorlevel", label: "Junior Level" },
        { value: "midlevel", label: "Mid Level" },
        { value: "seniorlevel", label: "Senior Level" },
      ],
    },
  ];
  const products = [
  
    {
      id: "1",
      name:"Onsite",
      mainId: 'Salesman',
      title: 'Salesman',
      postedon:'2024-7-20',
      description: ' The ability to build strong customer relationships, and a deep understanding of their product or service. They should also be persuasive, adaptable, and highly motivated.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Junior level',
      salary: '30K to above',
      color: "outdoor",
      size:"juniorlevel",
      projectType: 'Onsite',
      skills: ['Sale Techniques , Negotiating intersections , Managing Time, Safe driving '],
      Link: '/jobPlace1',
    },
   
    {
      id: "2",
      name:"Onsite",
      mainId: 'Data Entry',
      title: 'Data Entry',
      postedon:'2024-7-19',
      description: ' A data entry job involves accurately inputting, updating, and maintaining information in digital databases and systems. The role requires a keen eye for detail, proficiency with relevant software, and the ability to manage large volumes of data efficiently. It often includes tasks such as transcribing information from physical documents, verifying data for accuracy, and ensuring data security and confidentiality.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Mid level',
      salary: '35K to above',
      color: "indoor",
      size:"midlevel",
      projectType: 'Onsite',
      skills: ['Attenion to Detail, Proficiency in Data Entry Software, Typing Sp'],
      Link: '/jobPlace2',
    },
    {
      id: "3",
      name:"Onsite",
      mainId: 'Warehouse Supervisor',
      title: 'Warehouse Supervisor',
      postedon:'2024-7-20',
      description: ' A warehouse supervisor oversees daily operations, ensuring efficient storage, inventory management, and distribution of goods. They manage staff, coordinate logistics, and maintain safety standards.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Senior level',
      salary: '30K to above',
      color: "indoor",
      size:"seniorlevel",
      projectType: 'Onsite',
      skills: ['Leadership and Team Management, Organizational Skills'],
      Link: '/jobPlace3',
    },
    {
      id: "4",
      name:"Onsite",
      mainId: 'Marketing Person',
      title: 'Marketing Person',
      postedon:'2024-7-20',
      description: ' A marketing professional develops and implements strategies to promote products or services, aiming to increase brand awareness and drive sales. They analyze market trends, conduct research, and create targeted campaigns across various channels.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Mid level',
      salary: '30K to above',
      color: "outdoor",
      size:"midlevel",
      projectType: 'Onsite',
      skills: ['Strategic Planning, Creativity, Analytical Skills, Communica'],
      Link: '/jobPlace4',
    },
    {
      id: "5",
      name:"Onsite",
      mainId: 'Order Taker',
      title: 'Order Taker',
      postedon:'2024-8-01',
      description: ' An order taker processes customer orders, ensuring accuracy and efficiency in recording details and coordinating with the relevant departments. They provide excellent customer service, handle inquiries, and ensure timely order fulfillment.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Fresher',
      salary: '25K to above',
      color: "outdoor",
      size:"fresher",
      projectType: 'Onsite',
      skills: ['Attenstion to Detail, Customer Service Skills, Communication'],
      Link: '/jobPlace5',
    },
    {
      id: "6",
      name:"Onsite",
      mainId: 'Specialize in Warehouse',
      title: 'Specialize in Warehouse',
      postedon:'2024-7-19',
      description: ' A warehouse specialist manages inventory, ensures efficient storage and distribution of goods, and maintains accurate records. They handle shipping and receiving, conduct quality checks, and ensure compliance with safety and regulatory standards.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Senior level',
      salary: '50K to above',
      color: "indoor",
      size:"seniorlevel",
      projectType: 'Onsite',
      skills: ['Inventory Management, Organizational Skills, Attention to Detail'],
      Link: '/jobPlace6',
    },
    {
      id: "7",
      name:"Onsite",
      mainId: 'Driver',
      title: 'Driver',
      postedon:'2024-7-19',
      description: ' A driver is responsible for transporting goods or passengers safely and efficiently from one location to another. They ensure timely deliveries, maintain the vehicle in good working condition, and adhere to traffic laws and safety regulations.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Fresher',
      salary: '25K to above',
      color: "outdoor",
      size:"fresher",
      projectType: 'Onsite',
      skills: ['Safe Driving Practices, Time Management, Vehicle, Navigation'],
      Link: '/jobPlace7',
    },
    {
      id: "8",
      name:"Onsite",
      mainId: 'Store Keeper',
      title: 'Store Keeper',
      postedon:'2024-7-19',
      description: ' A storekeeper manages inventory levels, oversees the receipt and storage of goods, and ensures that products are organized and easily accessible. They handle stock records, manage orders, and maintain a clean and orderly storage environment.',
      location: ' Chauburji',
      company: 'Career grow',
      jobType: 'Full-time',
      experience: 'Junior level',
      salary: '35K to above',
      color: "indoor",
      size:"juniorlevel",
      projectType: 'Onsite',
      skills: ['Inventory Management, Organizational Skills, Attention to Detail'],
      Link: '/jobPlace8',
    },
    {
      id: "9",
      name:"Onsite",
      mainId: 'Gate Keeper',
      title: 'Gate Keeper',
      postedon:'2024-7-20',
      description: ' A gatekeeper controls access to a facility by monitoring entry and exit points, verifying the identity of individuals, and ensuring that security protocols are followed. They manage visitor logs, handle inquiries, and maintain a secure and orderly environment.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Fresher',
      salary: '35K to above',
      color: "indoor",
      size:"fresher",
      projectType: 'Onsite',
      skills: ['Security Awareness, Communication Skills, Attention to Detail, Cus'],
      Link: '/jobPlace9',
    },

    {
      id: "10",
      name:"Onsite",
      mainId: 'Loader Driver',
      title: 'Loader Driver',
      postedon:'2024-7-20',
      description: ' A loader driver operates vehicles or equipment to load and unload goods efficiently. They ensure safe and accurate handling of cargo, conduct routine vehicle maintenance, and follow safety protocols to prevent accidents and damage during loading and transportation.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Junior level',
      salary: '35K to above',
      color: "outdoor",
      size:"juniorlevel",
      projectType: 'Onsite',
      skills: ['Vehicle Operation, Load Management, Safety Protocols, Atten'],
      Link: '/jobPlace10',
    },
    {
      id: "11",
      name:"Onsite",
      mainId: 'Guard',
      title: 'Guard',
      postedon:'2024-7-20',
      description: ' A guard is responsible for protecting property and ensuring the safety of people within a designated area. They monitor surveillance equipment, patrol premises, enforce security protocols, and respond to incidents or emergencies as needed.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Junior level',
      salary: '30K to above',
      color: "indoor",
      size:"juniorlevel",
      projectType: 'Onsite',
      skills: ['Vigilance and Observation, Physical Fitness, Crisis Management, Know'],
      Link: '/jobPlace11',
    },
    {
      id: "12",
      name:"Onsite",
      mainId: 'Loader/Unloader',
      title: 'Loader/Unloader',
      postedon:'2024-7-19',
      description: ' A loader or unloader driver operates vehicles or equipment to efficiently load and unload cargo from transport vehicles. They ensure that goods are handled safely and accurately, adhere to safety standards, and perform routine checks and maintenance on their equipment.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Junior level',
      salary: '30K to above',
      color: "outdoor",
      size:"juniorlevel",
      projectType: 'Onsite',
      skills: ['Vehical Operation, Load Handling Safety Compliance, Attention to'],
      Link: '/jobPlace12',
    },
    {
      id: "13",
      name:"Onsite",
      mainId: 'Supervisor For Marketing',
      title: 'Supervisor For Marketing',
      postedon:'2024-7-21',
      description: ' A marketing supervisor oversees the development and execution of marketing strategies, manages the marketing team, and ensures that campaigns align with business goals. They analyze market trends, track campaign performance, and coordinate with other departments to optimize marketing efforts.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Senior level',
      salary: '50K to above',
      color: "indoor",
      size:"seniorlevel",
      projectType: 'Onsite',
      skills: ['Leadership and Team Management, Strategic Planning, Analytical'],
      Link: '/jobPlace13',
    },
    {
      id: "14",
      name:"Onsite",
      mainId: 'Accountant',
      title: 'Accountant',
      postedon:'2024-7-19',
      description: 'An accountant manages financial records, prepares financial statements, and ensures compliance with accounting standards and regulations. They analyze financial data, track expenditures, and provide insights for budgeting and financial planning.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Mid level',
      salary: '45K to above',
      color: "indoor",
      size:"midlevel",
      projectType: 'Onsite',
      skills: ['Financial Reporting, Attention to Detail, Analytical Skills, Know'],
      Link: '/jobPlace14',
    },
    {
      id: "15",
      name:"Onsite",
      mainId: 'Machinesman',
      title: 'Machinesman',
      postedon:'2024-7-20',
      description: ' A machineman operates and maintains machinery used in various industrial processes. They ensure machines run efficiently, perform regular maintenance, troubleshoot issues, and adhere to safety protocols to prevent breakdowns and ensure optimal performance.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Senior level',
      salary: '40K to above',
      color: "indoor",
      size:"seniorlevel",
      projectType: 'Onsite',
      skills: ['Machine Operation, Troubleshooting and Repair, Preventive Mainten'],
      Link: '/jobPlace15',
    },
    {
      id: "16",
      name:"Onsite",
      mainId: 'Machinesman for helper',
      title: 'Machinesman for helper',
      postedon:'2024-7-21',
      description: ' A machinist helper assists in operating and maintaining machinery, performs routine maintenance tasks, and supports machinists by preparing materials and tools. They ensure a clean and organized workspace, follow safety procedures, and help troubleshoot minor issues with equipment.',
      location: ' Chauburji',
      company: 'Career Grow',
      jobType: 'Full-time',
      experience: 'Junior level',
      salary: '35K to above',
      color: "indoor",
      size:"juniorlevel",
      projectType: 'Onsite',
      skills: ['Basic Machine Operation, Material Handling, Tool and Equipment Maintenance'],
      Link: '/jobPlace16',
    },

  ]

  return (
    <Layout>
<WhatsappButton/>
<div>

<img src='./assets/Logo.png' style={{width:"100%"}} alt='img'/>
</div>

<br/><br/>

<Products products={products} filters={filters} />  


    </Layout>
  )
}

export default Home
