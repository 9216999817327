import React from "react";
import dayjs from 'dayjs';
import { Link } from "react-router-dom";
import './JobCard.css'

function Product({ product }) {
  const date1 = dayjs(Date.now());
  const diffindays = date1.diff(product.postedon,'days');

  return (
    <div className='container-fluid'>

      
    
    <div className='container jobcard'>
        <div className='row innerjob d-flex justify-between items-center   '>
            <div className='col-md-8  iner items-start d-grid  '>
                <h1 className='fs-3 fw-bold'> {product.title} ~ {product.company}</h1>
                <p className='fs-5'>{product.jobType} &#x2022; {product.experience} &#x2022; {product.projectType}</p>
                <h5 className='  text-center ms-4' style={{width:"150px", backgroundColor:"#ccc"}}>{product.salary}</h5>

            
            <div className='d-flex   '>
                <h4 className="ms-4 ">Skills:</h4>
                <p><br/>  
                 {/* {product.skills} */}
                {`${product.skills.slice(0,10)}...`}
                </p>
                
                {/* {product.skills.map((skill)=>( */}
                   
                   {/* <p className='text-secondary py-1 me-2 '><br/> {`${product.skills.slice(0,100)},`}</p> */}
                {/* ))} */}
            </div>
        </div>
        <div className='col-md-3 items-center my-auto mx-auto'>
            <p className='text-secondary fs-6'>Posted {diffindays >1 ? `${diffindays} days` : `${diffindays} day`} ago </p>
           <Link to={product.Link}> <button className='btn  px-5 py-2' id='button'>Apply</button></Link> 
        </div>
        </div>



        <br/>
       
    </div>
    </div>
   
  );
}

export default Product;
